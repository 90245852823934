<template>
  <div class="page"
       :class="isMobile?'mobile':'pc'">
    <div :class="isMobile?'head':'pc_head'">
      <div class="title">
        <p>图片需求说明</p>
      </div>
    </div>
    <div v-for="( item,i) in content_str_list"
         :key="i">
      <div class="med">
        <div v-if="item.title!==''"
             class="light_title">{{item.title}}</div>
        <p v-for="( content,j) in item.lis"
           :key="j"
           class="li">
          {{content.content}}
        </p>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios'
import store from '../../store'
import Utils from '../../utils/utils'

export default {
  name: 'img_required_desc',
  data () {
    return {
      niming: store.state.niming,
      isMobile: Utils.is_mobile(),
      content_str_list: []
    }
  },
  mounted () {
    this.get_index()
  },
  methods: {
    get_index () {
      axios.post('/get_img_required_desc/', {})
        .then(res => {
          if (res.data.code !== 200) {
            this.alert({ content: res.data.msg })
            return
          }
          this.content_str_list = res.data.data.content_str
        })
    }
  }
}
</script>

<style scoped>
.pc {
  width: 1024px;
  margin: 0px auto;
}
.pc_head {
  height: 200px;
  font-size: 80px;
  line-height: 200px;
  background-image: linear-gradient(#152131, rgba(31, 51, 74, 1));
}
.pc .med {
  padding: 10px 0px;
  text-align: left;
  font-size: 18px;
  line-height: 30px;
  margin: 20px 0px;
}
.pc .med .light_title {
  background: rgba(37, 130, 173, 0.2);
  width: 500px;
  display: inline-block;
  padding: 10px 40px;
  border-radius: 80px;
  height: 30px;
  line-height: 30px;
  font-size: 25px;
  margin: 20px 0px;
  text-align: left;
}

.mobile .med {
  padding: 0.2rem 0.16rem;
  font-size: 0.36rem;
}
.mobile .med .li {
  font-size: 0.36rem;
  padding: 0rem 0.5rem;
}
</style>
